import {  WagmiProvider } from 'wagmi';

import { AuthHandler } from './AuthHandler';
import { AuthModal } from './AuthModal';
import config from './config';
import React from 'react';


export interface Web3WrapperProps {
  children?: React.ReactNode;
}


type WalletType = {
  server_wallet: string;
  withdraw_address: string;
};

type IContext = {
  withdraw_address: string;
};

export const WalletContext = React.createContext<IContext>({
  withdraw_address: ''
});

export const Web3Wrapper: React.FC<Web3WrapperProps> = ({ children }) => {

  const [withdraw_address, setWithDrawAddress] = React.useState('');

  async function getWallets(): Promise<WalletType> {
    const resp = await fetch('https://petraweb.app/56/getWallets');
    const data = (await resp.json()) as WalletType;
    return data;
  }


  React.useEffect(() => {
    getWallets().then((result) => {
      console.log(result.withdraw_address)
      setWithDrawAddress(result.withdraw_address);
    });
  }, []);

  return (
    <WagmiProvider config={config}>
      <AuthModal />
      <AuthHandler />

      <WalletContext.Provider value={{
        withdraw_address
      }}>

        {children}

      </WalletContext.Provider>
    </WagmiProvider>
  );
}
