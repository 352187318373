import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { HashRouter } from 'react-router-dom';

import { queryClient } from 'clients/api';
// import { AnalyticProvider } from 'libs/analytics';
import { ErrorBoundary } from 'libs/errors';
import { SentryErrorInfo } from 'libs/errors/SentryErrorInfo';
import { Web3Wrapper } from 'libs/wallet';
import { MuiThemeProvider } from 'theme/MuiThemeProvider';

import Routes from './Routes';

const NotificationCenter = lazy(() => import('libs/notifications/NotificationCenter'));
const AppVersionChecker = lazy(() => import('containers/AppVersionChecker'));

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <HashRouter>
        <MuiThemeProvider>
          <Web3Wrapper>
            <Routes />

            <Suspense>
              <NotificationCenter />
            </Suspense>

            <Suspense>
              <AppVersionChecker />
            </Suspense>

            <SentryErrorInfo />
          </Web3Wrapper>
        </MuiThemeProvider>
      </HashRouter>
    </ErrorBoundary>
  </QueryClientProvider>
);

export default App;
